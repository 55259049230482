import {Injectable} from '@angular/core';
import {Company} from '../_models/company';
import {Address} from '../_models/address';
import {Person} from '../_models/person';

@Injectable({
    providedIn: 'root'
})
export class OwnerInfoService {
    company: Company = new Company();
    address: Address = new Address();
    person: Person = new Person();
    showNameInInforbar = false;
    showNameInInforbarMobile = false;
    showLogoInInforbar = false;
    logo: string;

    constructor() {
    }

    /**
     * Set company for page
     * @param company name
     */
    setCompany(company: {}) {
        this.company = new Company();
        this.company.createInstance(company);
    }

    /**
     * Get the company
     */
    getCompany() {
        return this.company;
    }

    /**
     * set address of company
     * @param address of company
     */
    setAddress(address: {}) {
        this.address = new Address();
        this.address.createInstance(address);
        this.company.address = this.address;
    }

    /**
     * get address of company
     */
    getAddress() {
        return this.address;
    }

    /**
     * Set owner of company
     * @param person: owner of company
     */
    setPerson(person: {}) {
        this.person = new Person();
        this.person.createInstance(person);
        this.company.owner = this.person;
    }

    /**
     * Get owner of company
     */
    getPerson() {
        return this.person;
    }
}
