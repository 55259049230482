import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    constructor(public translate: TranslateService) {
    }
    private language: string;
    public setLanguage(lang: string) {
        this.translate.use(lang);
        this.language = lang;
    }
    public start() {
        if (this.language === undefined) {
            const browserLang = this.translate.getBrowserLang();
            if (browserLang.match(/en|de|tr/)) {
                this. translate.use(browserLang);
                this.language = browserLang;
            } else {
                this.language = 'en';
                this.translate.use('en');
            }
        } else {
            this.translate.use(this.language);
        }
    }
    public getLanguage(): string {
        return this.language;
    }
}
