import {Company} from './company';
import {Image} from "./image";

export class FooterAttributes {
    company: Company;
    showLocation: boolean;
    showSocialMedia: boolean;
    showContact: boolean;
    singlePerson: boolean;
    logo: Image;
}
