import {Image} from './image';
import {YoutubeVideo} from "./youtube-video";
import {NavLinkConfig} from './nav-link-config';

export class CarouselAttributes {
    images: Array<Image>;
    videos: Array<YoutubeVideo>;
    navConfig: NavLinkConfig;
    bgColor: string;
    sectionHeading: string;
    sectionSubHeading: string;
}
