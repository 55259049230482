<div class="wow animate__animated animate__fadeInUp w-100 p-5 mh-100 row m-0 w-100" [ngClass]="{'bg-section-primary': attributes?.bgColor === 'primary',
     'bg-section-secondary': attributes?.bgColor === 'secondary'}" [id]="attributes?.navConfig?.id" spyTarget="{{attributes?.navConfig?.id}}">
    <div class="row w-100 my-auto mx-auto h-100">
        <h1 *ngIf="attributes?.sectionHeading" class="section-title">{{attributes?.sectionHeading | translate}}</h1>


    </div>
    <div class="row w-100 my-auto mx-auto h-50">
        <h5 *ngIf="attributes?.sectionSubHeading" class="text-muted">{{attributes?.sectionSubHeading | translate}}</h5>
    </div>
    <div class="text-normal text-image" [innerHTML]="attributes?.sectionText | translate"></div>
</div>

