<nav class="navbar navbar-expand-lg navbar-dark fixed-top animate__animated animate__fadeInDown animate__delay-1s p-2 shadow-sm"
     [ngClass]="{'bg-transparent': isTop === true && currentRoute === '', 'bg-dark': isTop === false || currentRoute !== '',
     'bg-mobile-dark': navbarOpen === true}">
    <a class="navbar-brand" href="#"><img class="mr-2 ml-2" [attr.src]="logo" alt="{{companyName}}"
                                          *ngIf="showLogo === true"/> <span
            [ngClass]="{'d-none ': showNameMobile === true, 'd-md-inline-block': showNameMobile === true}" *ngIf="showName === true">{{companyName}}</span> </a>
    <button class="navbar-toggler"
            [ngClass]="{'collapsed': navbarOpen === false, 'toggler-active': navbarOpen === true}"
            (click)="toggleNavbar()"
            type="button" data-target="#navbarCollapse"
            aria-controls="navbarCollapse" [attr.aria-expanded]="navbarOpen" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse"
         [ngClass]="{'show': navbarOpen === true}">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item" *ngFor="let section of sections">
                <a *ngIf="section?.navConfig?.label !== 'noNav'" class="nav-link pr-4 pl-4"
                   (click)="scrollToSection(section?.navConfig?.id)"
                   [routerLink]="'/'"
                   [ngClass]="{'active': currentSection === section?.navConfig?.id && currentRoute === ''}">{{section?.navConfig?.label | translate}}</a>
            </li>
        </ul>
        <!--  CUSTOM PAGES -->
        <ul class="navbar-nav">
            <li class="nav-item" *ngFor="let page of pages">
                <a *ngIf="page?.navConfig?.id !== 'impressum' && page?.navConfig?.id !== 'datenschutz'" class="nav-link pr-4 pl-4" (click)="toggleNavbar()"
                   routerLink="/page/{{page?.navConfig?.id}}" routerLinkActive="active"
                   [ngClass]="{'active': currentSection === page?.navConfig?.id}">{{page?.navConfig?.label}}</a>
            </li>
            <li class="nav-item dropdown pr-4 pl-4" *ngIf="showLanguageDropdown === true">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Language
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown" >
                    <ng-container *ngFor="let l of languages">
                        <a class="dropdown-item" target="_blank" (click)="languageService.setLanguage(l?.value)" >{{l?.name}}</a>
                    </ng-container>

                </div>
            </li>
        </ul>
    </div>
</nav>
