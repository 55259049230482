import {Injectable} from '@angular/core';
import {LandingPageComponent} from '../sections/landing-page/landing-page.component';
import {LandingPageSectionAttributes} from '../_models/landing-page-section-attributes';
import {HeaderTextSectionComponent} from '../sections/header-text-section/header-text-section.component';
import {HeaderTextAttributes} from '../_models/header-text-attributes';
import {ImageTextSectionComponent} from '../sections/image-text-section/image-text-section.component';
import {ImageTextAttributes} from '../_models/image-text-attributes';
import {FeatureSectionComponent} from '../sections/feature-section/feature-section.component';
import {FeatureSectionAttributes} from '../_models/feature-section-attributes';
import {ContactFormAttributes} from '../_models/contact-form-attributes';
import {ContactFormComponent} from '../sections/contact-form/contact-form.component';
import {FooterComponent} from '../sections/footer/footer.component';
import {FooterAttributes} from '../_models/footer-attributes';
import {CarouselComponent} from '../sections/carousel/carousel.component';
import {CarouselAttributes} from '../_models/carousel-attributes';

@Injectable({
    providedIn: 'root'
})
export class ComponentService {
    private components = {
        LandingPage: LandingPageComponent,
        HeaderText: HeaderTextSectionComponent,
        ImageText: ImageTextSectionComponent,
        Features: FeatureSectionComponent,
        ContactForm: ContactFormComponent,
        Footer: FooterComponent,
        Carousel: CarouselComponent
    };

    private componentAttributes = {
        LandingPage: new LandingPageSectionAttributes(),
        HeaderText: new HeaderTextAttributes(),
        ImageText: new ImageTextAttributes(),
        Features: new FeatureSectionAttributes(),
        ContactForm: new ContactFormAttributes(),
        Footer: new FooterAttributes(),
        Carousel: new CarouselAttributes()
    };

    constructor() {
    }

    /**
     * get a component by Name
     * @param name of the cmponent
     */
    getComponent(name: string) {
        return this.components[name];
    }

    /**
     * get attribute class of component
     * @param componentName: name of the component
     */
    getComponentAttributes(componentName: string) {
        // create always new instances
        this.componentAttributes = {
            LandingPage: new LandingPageSectionAttributes(),
            HeaderText: new HeaderTextAttributes(),
            ImageText: new ImageTextAttributes(),
            Features: new FeatureSectionAttributes(),
            ContactForm: new ContactFormAttributes(),
            Footer: new FooterAttributes(),
            Carousel: new CarouselAttributes()
        };
        return this.componentAttributes[componentName];
    }
}
