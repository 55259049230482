import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {InfobarComponent} from './infobar/infobar.component';
import {SafePipe} from './_pipes/safe.pipe';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {NgwWowModule} from 'ngx-wow';
import {SocialbarComponent} from './socialbar/socialbar.component';
import {HeaderTextSectionComponent} from "./sections/header-text-section/header-text-section.component";
import { ImageTextSectionComponent } from './sections/image-text-section/image-text-section.component';
import { LandingPageComponent } from './sections/landing-page/landing-page.component';
import { FeatureSectionComponent } from './sections/feature-section/feature-section.component';
import { ContactFormComponent } from './sections/contact-form/contact-form.component';
import { FooterComponent } from './sections/footer/footer.component';
import { SectionWrapperComponent } from './sections/section-wrapper/section-wrapper.component';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import {ThemeService} from './_services/theme.service';
import {defaultTheme} from './_themes/default-theme';
import {ThemeModule} from './theme/theme.module';
import {NgxCaptchaModule} from 'ngx-captcha';
import { PageManagerComponent } from './pages/page-manager/page-manager.component';
import { CustomHtmlComponent } from './pages/custom-html/custom-html.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { CarouselComponent } from './sections/carousel/carousel.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbCarouselModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ImageGalleryComponent } from './pages/image-gallery/image-gallery.component';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import {ScrollSpyModule} from 'ng-spy';
import 'hammerjs';
import {GalleryModule} from '@ngx-gallery/core';
import {SharedModule} from './sharedModule';
@NgModule({
    declarations: [
        AppComponent,
        InfobarComponent,
        SafePipe,
        SocialbarComponent,
        HeaderTextSectionComponent,
        ImageTextSectionComponent,
        LandingPageComponent,
        FeatureSectionComponent,
        ContactFormComponent,
        FooterComponent,
        SectionWrapperComponent,
        ImpressumComponent,
        PageManagerComponent,
        CustomHtmlComponent,
        PageNotFoundComponent,
        CarouselComponent,
        ImageGalleryComponent,
        CookieConsentComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgwWowModule,
        ThemeModule.forRoot({
            themes: [defaultTheme],
            active: 'default'
        }),
        NgxCaptchaModule,
        NgbModule,
        NgbCarouselModule,
        ScrollSpyModule,
        GalleryModule,
        SharedModule
    ],
    providers: [ThemeService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
