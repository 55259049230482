<div class="wow animate__animated animate__fadeInUp w-100 p-5 mh-100 row m-0 w-100" [ngClass]="{'bg-section-primary': attributes?.bgColor === 'primary',
     'bg-section-secondary': attributes?.bgColor === 'secondary'}" [id]="attributes?.navConfig?.id" spyTarget="{{attributes?.navConfig?.id}}">

    <div class="row w-100 my-auto mx-auto h-100">
        <!--        left col-->
        <div class=" col-sm-12 col-md-12 col-lg-6 col-xl-6 text-left p-0"
             [ngClass]="{'order-0': attributes?.leftColContent === 'text', 'order-1': attributes?.leftColContent === 'image', 'my-auto': !attributes?.sectionHeading || !attributes?.sectionSubHeading,
             'pr-lg-4': attributes?.leftColContent === 'text', 'pl-lg-4': attributes?.leftColContent === 'image'}">
            <div class="w-100 mb-4" *ngIf="attributes?.sectionHeading || attributes?.sectionSubHeading">
                <h1 *ngIf="attributes?.sectionHeading"
                    class="section-title text-left">{{attributes?.sectionHeading | translate}}</h1>
                <h5 *ngIf="attributes?.sectionSubHeading"
                    class="text-muted text-left">{{attributes?.sectionSubHeading | translate}}</h5>
            </div>
            <div class="text-normal text-image" [innerHTML]="attributes?.sectionText | translate"></div>
        </div>

        <!--        right col-->
        <div class="my-auto col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center h-100 p-0"
             [ngClass]="{'order-0': attributes?.leftColContent === 'image', 'order-1': attributes?.leftColContent === 'text',
             'pr-lg-4': attributes?.leftColContent === 'image', 'pl-lg-4': attributes?.leftColContent === 'text'}">
            <div class="mb-3">
                <a *ngIf="attributes?.sectionImageSrc.includes('youtube') === false && attributes?.sectionImageSrc.includes('youtu.be') === false; else youtubeVideo"
                   [href]="attributes?.sectionImageSrc" [attr.data-lightbox]="attributes?.sectionImageSrc">
                    <img class="img-thumbnail w-100 h-100 rounded lozad"
                         src="assets/img/system/loader.svg" [attr.data-src]="attributes?.sectionImageSrc"
                         alt="Bild"/>
                </a>

                <ng-template #youtubeVideo>
                    <iframe class="w-100" height="315" [src]="attributes?.sectionImageSrc | safe: 'resourceUrl'"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                </ng-template>
            </div>
        </div>
    </div>
</div>
