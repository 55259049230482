<div class="wow animate__animated animate__fadeInUp w-100 p-5 mh-100 row m-0 w-100" [ngClass]="{'bg-section-primary': attributes?.bgColor === 'primary',
     'bg-section-secondary': attributes?.bgColor === 'secondary'}"  [id]="attributes?.navConfig?.id" spyTarget="{{attributes?.navConfig?.id}}">

    <div class="w-100 h-25 mb-4" *ngIf="attributes?.sectionHeading || attributes?.sectionSubHeading">
        <h1 *ngIf="attributes?.sectionHeading"
            class="section-title text-center">{{attributes?.sectionHeading | translate}}</h1>
        <h5 *ngIf="attributes?.sectionSubHeading"
            class="text-muted text-center">{{attributes?.sectionSubHeading | translate}}</h5>
    </div>

    <div *ngIf="mailSentSuccess === true" class="col-12">
        <div class="alert alert-success">{{"CONTACT_FORM.SUCCESS_TEXT" | translate}}
        </div>
    </div>

    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0 pr-lg-4" *ngIf="attributes?.showGoogleMaps === true">
        <iframe [src]="getEncodedAddress() | safe: 'resourceUrl'"
                class="w-100 h-100" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
                tabindex="0"></iframe>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0 align-self-center pl-lg-4">
        <div class="mb-3">{{"CONTACT_FORM.TEXT" | translate}}</div>
        <form class="form" [formGroup]="fgContact" (ngSubmit)="sendMail(fgContact?.value)">
            <div class="form-group">
                <label for="c_name">{{"CONTACT_FORM.FULL_NAME" | translate}}<span class="text-danger">*</span> </label>
                <input formControlName="c_name" id="c_name" type="text" class="form-control"
                       [ngClass]="{'is-invalid': fgContact.get('c_name').touched && (fgContact.get('c_name').hasError('required') || fgContact.get('c_name').hasError('minlength'))}">
                <ul class="text-danger">
                    <li *ngIf="fgContact.get('c_name').touched && fgContact.get('c_name').hasError('required')">
                        <small class="text-danger">
                            {{"CONTACT_FORM.NAME_DANGER_1" | translate}}
                        </small>
                    </li>
                    <li *ngIf="fgContact.get('c_name').touched && fgContact.get('c_name').hasError('minlength')">
                        <small class="text-danger">
                            {{"CONTACT_FORM.NAME_DANGER_2" | translate}}
                        </small>
                    </li>
                </ul>
            </div>

            <div class="form-group">
                <label for="c_email">{{"CONTACT_FORM.EMAIL" | translate}}<span class="text-danger">*</span> </label>
                <input type="email" formControlName="c_email" id="c_email" class="form-control"
                       [ngClass]="{'is-invalid': fgContact.get('c_email').touched && (fgContact.get('c_email').hasError('required') || fgContact.get('c_email').hasError('minlength') || fgContact.get('c_email').hasError('email'))}">
                <ul class="text-danger">
                    <li *ngIf="fgContact.get('c_email').touched && fgContact.get('c_email').hasError('required')">
                        <small class="text-danger">
                            {{"CONTACT_FORM.EMAIL_DANGER_1" | translate}}
                        </small>
                    </li>
                    <li *ngIf="fgContact.get('c_email').touched && fgContact.get('c_email').hasError('minlength')">
                        <small class="text-danger">
                            {{"CONTACT_FORM.EMAIL_DANGER_2" | translate}}
                        </small>
                    </li>
                    <li *ngIf="fgContact.get('c_email').touched && fgContact.get('c_email').hasError('email')">
                        <small class="text-danger">
                            {{"CONTACT_FORM.EMAIL_DANGER_3" | translate}}
                        </small>
                    </li>
                </ul>
            </div>

            <div class="form-group">
                <label for="c_subject">{{"CONTACT_FORM.SUBJECT" | translate}}<span class="text-danger">*</span> </label>
                <input type="text" formControlName="c_subject" id="c_subject" class="form-control"
                       [ngClass]="{'is-invalid': fgContact.get('c_subject').touched && (fgContact.get('c_subject').hasError('required'))}">
                <ul class="text-danger">
                    <li *ngIf="fgContact.get('c_subject').touched && fgContact.get('c_subject').hasError('required')">
                        <small class="text-danger">
                            {{"CONTACT_FORM.SUBJECT_DANGER" | translate}}
                        </small>
                    </li>
                </ul>
            </div>

            <div class="form-group">
                <label for="c_message">{{"CONTACT_FORM.MESSAGE" | translate}}<span class="text-danger">*</span> </label>
                <textarea class="form-control" formControlName="c_message" id="c_message"
                          [ngClass]="{'is-invalid': fgContact.get('c_message').touched && (fgContact.get('c_message').hasError('required') || fgContact.get('c_message').hasError('minlength'))}"
                          rows="9"></textarea>
                <ul class="text-danger">
                    <li *ngIf="fgContact.get('c_message').touched && fgContact.get('c_message').hasError('required')">
                        <small class="text-danger">
                            {{"CONTACT_FORM.TEXT_DANGER_1" | translate}}
                        </small>
                    </li>
                    <li *ngIf="fgContact.get('c_message').touched && fgContact.get('c_message').hasError('minlength')">
                        <small class="text-danger">
                            {{"CONTACT_FORM.TEXT_DANGER_2" | translate}}
                        </small>
                    </li>
                </ul>
            </div>

            <div class="form-group">
                <label for="c_captcha">{{"CONTACT_FORM.SECURITY_QUESTION" | translate}}<span class="text-danger">*</span> </label>
                <ngx-recaptcha2
                        id="c_captcha"
                        [siteKey]="attributes?.recaptchaSiteKey"
                        (success)="getToken($event)"
                        formControlName="recaptcha">
                </ngx-recaptcha2>
                <ul class="text-danger">
                    <li *ngIf="fgContact.get('recaptcha').touched && fgContact.get('recaptcha').hasError('required')">
                        <small class="text-danger">
                            {{"CONTACT_FORM.SECURITY_DANGER" | translate}}
                        </small>
                    </li>
                </ul>
            </div>
            <p> {{submitMessage | translate}} <p>
            <div class="form-group">
                <button type="submit" class="btn btn-primary float-right">{{"CONTACT_FORM.SUBMIT" | translate}}</button>
            </div>
        </form>
    </div>
</div>
