<div class="col-12 d-flex justify-content-center my-auto background h-100 w-100" [ngStyle]="{'background-image': 'url(' + attributes?.bgUrl + ')'}" [id]="attributes?.navConfig?.id" spyTarget="{{attributes?.navConfig?.id}}">
    <div class="text-center my-auto" style="z-index: 3">
        <div *ngIf="attributes.image">
            <img  src="{{attributes.image.path}}" style="width: 50%; height: 50%; display: block; margin-left: auto; margin-right: auto;">

            <h2 [ngStyle]="{'color': attributes?.textColor}" class="small-teaser animate__animated {{attributes?.smallTeaserAnimation}} animate__delay-1s">{{attributes?.smallTeaser | translate}}</h2>
        </div>
        <div *ngIf="!attributes.image">
            <h1 [ngStyle]="{'color': attributes?.textColor}" class="big-teaser animate__animated {{attributes?.bigTeaserAnimation}} m-0">{{attributes?.bigTeaser}}</h1><br>
            <h2 [ngStyle]="{'color': attributes?.subTitleTextColor}" class="small-teaser animate__animated {{attributes?.smallTeaserAnimation}} animate__delay-1s">{{attributes?.smallTeaser}}</h2>
            <a *ngIf="attributes?.showButton === true && !attributes?.buttonUrl.startsWith('/')" [href]="attributes?.buttonUrl" [attr.target]="attributes.buttonTarget" class="btn btn-primary btn-lg">{{attributes?.buttonLabel}}</a>
            <a *ngIf="attributes?.showButton === true && attributes?.buttonUrl.startsWith('/')" [routerLink]="attributes?.buttonUrl" [attr.target]="attributes.buttonTarget" class="btn btn-primary btn-lg">{{attributes?.buttonLabel}}</a>
        </div>

    </div>
    <div class="theme-bg-primary">
    </div>
</div>
<div style="cursor: pointer" (click)="scrollToNextSection()" class="angle-down-landing animate__animated animate__delay-2s animate__bounce">
    <i [ngStyle]="{'color': attributes?.textColor}" class="fas fa-angle-down fa-3x"></i>
</div>
