import {Component, Input, OnInit} from '@angular/core';
import {OwnerInfoService} from '../_services/owner-info.service';
import {PageService} from '../_services/page.service';

@Component({
    selector: 'app-socialbar',
    templateUrl: './socialbar.component.html',
    styleUrls: ['./socialbar.component.scss']
})
export class SocialbarComponent implements OnInit {
    twitterUrl: string;
    facebookUrl: string;
    instagramUrl: string;
    whatsappUrl: string;
    twitchUrl: string;
    etsyUrl: string;
    tiktokUrl: string;
    vimeoUrl: string;
    pinterestUrl: string;
    youtubeUrl: string;

    @Input() show: boolean;
    showSocialbar = true;
    currentPage: string;

    constructor(private ownerInfoService: OwnerInfoService, private pageService: PageService) {

    }

    /**
     * Toggles show or hide social bar
     */
    toggleSocialBar() {
        this.showSocialbar = this.showSocialbar !== true;
    }

    ngOnInit(): void {
        this.pageService.getCurrentCustomPageRoute().subscribe((page) => {
            page === 'gallery' ? this.showSocialbar = false : this.showSocialbar = true;
        });

        this.twitterUrl = this.ownerInfoService.getCompany().twitter;
        this.facebookUrl = this.ownerInfoService.getCompany().facebook;
        this.instagramUrl = this.ownerInfoService.getCompany().instagram;
        this.whatsappUrl = this.ownerInfoService.getCompany().whatsapp;
        this.twitchUrl = this.ownerInfoService.getCompany().twitch;
        this.etsyUrl = this.ownerInfoService.getCompany().etsy;
        this.vimeoUrl = this.ownerInfoService.getCompany().vimeo;
        this.pinterestUrl = this.ownerInfoService.getCompany().pinterest;
        this.youtubeUrl = this.ownerInfoService.getCompany().youtube;
        this.tiktokUrl = this.ownerInfoService.getCompany().tiktok;
    }

}
