import {NavLinkConfig} from './nav-link-config';
import {Image} from "./image";

export class LandingPageSectionAttributes {
    navConfig: NavLinkConfig;
    bigTeaser = 'PresentCMS';
    smallTeaser = 'Eine einfache Webseite...';
    buttonLabel = 'External URL';
    buttonUrl = '#';
    buttonTarget = '_blank';
    showButton = true;
    bgUrl: string;
    bigTeaserAnimation: string;
    smallTeaserAnimation: string;
    textColor: string;
    subTitleTextColor: string;
    image: Image
}
