import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContactFormAttributes} from '../../_models/contact-form-attributes';
import {OwnerInfoService} from '../../_services/owner-info.service';
import {ScrollSpyService} from 'ng-spy';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() attributes: ContactFormAttributes;

    mailSentSuccess = false;
    fgContact: FormGroup;
    private subSendMessage: Subscription = new Subscription();
    private subPostToken: Subscription = new Subscription();
    submitMessage: string;


    constructor(private http: HttpClient, private fb: FormBuilder,
                private ownerInfoService: OwnerInfoService, private spyService: ScrollSpyService) {
        this.fgContact = this.fb.group({
            c_name: [null, Validators.compose([Validators.required, Validators.minLength(3)])],
            c_email: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.email])],
            c_subject: [null, Validators.compose([Validators.required])],
            recaptcha: ['', Validators.required],
            c_message: [null, Validators.compose([Validators.required, Validators.minLength(10)])]
        });
        this.submitMessage  = "";
    }

    ngAfterViewInit(): void {
        this.spyService.spy({thresholdTop: 300, thresholdBottom: 400});
    }

    ngOnInit(): void {
    }

    /**
     * Send form values via mail
     * @param value of the form
     */
    sendMail(value: any) {
        if (this.fgContact.valid) {
            // merge person data to mail data
            Object.assign(value, {
                c_to_email: this.ownerInfoService.getCompany().email,
                c_to_name: this.ownerInfoService.getCompany().owner.firstname + ' ' + this.ownerInfoService.getCompany().owner.lastname
            });

            this.subSendMessage = this.http.post(this.attributes.mailerUrl, value).subscribe((mailResp: any) => {
                if (mailResp.status === true) {
                    this.mailSentSuccess = true;
                    setTimeout(() => {
                        this.mailSentSuccess = false;
                    }, 7000);
                    this.submitMessage = "CONTACT_FORM.SUCCESS_TEXT";
                    this.fgContact.reset();
                }
            }, (error => {
                console.log(error);
            }));
        } else {
            this.validateAllFormFields(this.fgContact);
            this.mailSentSuccess = false;
            this.submitMessage = "CONTACT_FORM.FAILED_TEXT"
        }
    }

    /**
     * verify token in backend
     * @param token from google to verify human
     */
    verifyCaptchaToken(token: any) {
        return this.http.post(this.attributes.tokenVerificationUrl, token);
    }

    /**
     * get the token from google to verify in backend
     * @param $event token to verify
     */
    getToken($event) {
        this.subPostToken = this.verifyCaptchaToken({t: $event}).subscribe((resp: any) => {
            if (resp.success === false) {
                this.fgContact.get('recaptcha').markAsTouched({onlySelf: true});
                this.fgContact.get('recaptcha').setErrors({required: true});
            }
        });
    }

    /**
     * Validate all formcontrols
     * @param formGroup to validate
     */
    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({onlySelf: true});
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    /**
     * URl encodes the address and returns full iFrame source for google maps embed
     */
    getEncodedAddress() {
        return 'https://maps.google.com/maps?width=100%&height=600&hl=de&q=' + encodeURI(this.attributes.companyAddress.street + ' ' +
            this.attributes.companyAddress.houseNr + ', ' + this.attributes.companyAddress.plz + ' ' + this.attributes.companyAddress.city + ', '
            + this.attributes.companyAddress.country) + '&ie=UTF8&t=&z=16&iwloc=B&output=embed';
    }

    ngOnDestroy(): void {
        this.subPostToken.unsubscribe();
        this.subSendMessage.unsubscribe();
    }
}
