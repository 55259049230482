import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import lozad from 'lozad';
import {HeaderTextAttributes} from '../../_models/header-text-attributes';
import {ScrollSpyService} from 'ng-spy';

@Component({
    selector: 'header-text-section',
    templateUrl: './header-text-section.component.html',
    styleUrls: ['./header-text-section.component.scss']
})
export class HeaderTextSectionComponent implements OnInit, AfterViewInit {
    attributes: HeaderTextAttributes;

    constructor(private spyService: ScrollSpyService) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        const observer = lozad();
        observer.observe();

        this.spyService.spy({thresholdTop: 300, thresholdBottom: 400});
    }

}
