<div class="w-100 h-auto row p-0 m-0 d-flex justify-content-center bg-dark text-white">
    <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 text-center p-4 border-right border-secondary" *ngIf="attributes?.logo">
        <img  src="{{attributes.logo}}" style="height:auto; width: auto; max-width: 100%; max-height: 88px !important; display: block; margin-left: auto; margin-right: auto;">
        <div class="h-75">
            <ul class="list-unstyled w-100">
                <li>{{attributes?.company?.fullName}}</li>
                <li>{{attributes?.company?.address?.street + ' ' + attributes?.company?.address?.houseNr + ', ' + attributes?.company?.address?.plz + ' ' + attributes?.company?.address?.city}}</li>
            </ul>
        </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 text-center p-4 border-right border-secondary" *ngIf="attributes?.showLocation && !attributes?.logo">
        <div class="h-25">
            <h4>{{attributes?.singlePerson === true ? 'Mein' : 'Unser'}} Standort</h4>
        </div>
        <div class="h-75">
            <ul class="list-unstyled w-100">
                <li>{{attributes?.company?.name}}</li>
                <li>{{attributes?.company?.address?.street + ' ' + attributes?.company?.address?.houseNr + ', ' + attributes?.company?.address?.plz + ' ' + attributes?.company?.address?.city}}</li>
            </ul>
        </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 text-center p-4 border-right border-secondary" *ngIf="attributes?.showContact">
        <div class="h-25"><h4>{{'FOOTER.CONTACT' | translate}}</h4></div>
        <div class="h-75">
            <ul class="list-unstyled w-100">
                <li *ngIf="attributes?.company?.phone || attributes?.company?.owner?.phone">Tel.: <a
                        [attr.href]="'tel:' + attributes?.company?.phone || attributes?.company?.owner?.phone"> {{attributes?.company?.phone || attributes?.company?.owner?.phone}}</a>
                </li>
                <li *ngIf="attributes?.company?.mobile1">Mobile TR: <a
                        [attr.href]="'tel: ' + attributes?.company?.mobile1"> {{attributes?.company?.mobile1}} </a>
                </li>
                <li *ngIf="attributes?.company?.mobile2">MobilDe: <a
                        [attr.href]="'tel: ' + attributes?.company?.owner?.mobile"> {{attributes?.company?.owner?.mobile}} </a>
                </li>
                <li>Email: <a
                        [attr.href]="'mailto:' + attributes?.company?.email || attributes?.company?.owner?.email"> {{attributes?.company?.email || attributes?.company?.owner?.email}}</a>
                </li>
                <li *ngIf="attributes?.company?.fax">Fax: {{attributes?.company?.fax}}</li>
            </ul>
        </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 text-center p-4 border-right border-secondary" *ngIf="attributes?.showSocialMedia">
        <div class="h-25"><h4>{{'FOOTER.SOCIAL_MEDIA' | translate}}</h4></div>
        <div class="h-75 d-flex">
            <div class="align-items-center mx-auto">
                <ul class="list-unstyled w-100 text-left">
                    <li *ngIf="attributes?.company?.facebook" class="sm-icon-footer" id="facebook">&nbsp; <a
                            [attr.href]="attributes?.company?.facebook"
                            target="_blank">Facebook</a>
                    </li>
                    <li *ngIf="attributes?.company?.instagram" class="sm-icon-footer" id="instagram">&nbsp; <a
                            [attr.href]="attributes?.company?.instagram"
                            target="_blank">Instagram</a>
                    </li>
                    <li *ngIf="attributes?.company?.vimeo" class="sm-icon-footer" id="vimeo">&nbsp; <a
                            [attr.href]="attributes?.company?.vimeo"
                            target="_blank">Vimeo</a>
                    </li>
                    <li *ngIf="attributes?.company?.twitter" class="sm-icon-footer" id="twitter">&nbsp; <a
                            [attr.href]="attributes?.company?.twitter"
                            target="_blank">Twitter</a>
                    </li>
                    <li *ngIf="attributes?.company?.pinterest" class="sm-icon-footer" id="pinterest">&nbsp; <a
                            [attr.href]="attributes?.company?.pinterest"
                            target="_blank">Pinterest</a>
                    </li>
                    <li *ngIf="attributes?.company?.twitch" class="sm-icon-footer" id="twitch">&nbsp; <a
                            [attr.href]="attributes?.company?.twitch"
                            target="_blank">Twitch</a>
                    </li>
                    <li *ngIf="attributes?.company?.youtube" class="sm-icon-footer" id="youtube">&nbsp; <a
                            [attr.href]="attributes?.company?.youtube"
                            target="_blank">Youtube</a>
                    </li>
                    <li *ngIf="attributes?.company?.whatsapp" class="sm-icon-footer" id="whatsapp">&nbsp; <a
                            [attr.href]="'https://wa.me/' + attributes?.company?.whatsapp"
                        target="_blank">Whatsapp</a>
                    </li>
                    <li *ngIf="attributes?.company?.etsy" class="sm-icon-footer" id="etsy">&nbsp; <a
                            [attr.href]="attributes?.company?.etsy"
                            target="_blank">Etsy</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 text-center p-4 d-flex align-items-center">
        <ul class="list-unstyled w-100">
            <li>Copyright &copy; 2021 {{attributes?.company?.name}}</li>
            <li><a routerLink="/page/impressum">{{'FOOTER.IMPRINT' | translate}}</a></li>
            <li><a routerLink="/page/datenschutz">{{'FOOTER.PRIVACY' | translate}}</a></li>
        </ul>
    </div>
</div>
